import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  VideoText,
  VideoCTA,
  VideoDotA,
  VideoDotB,
  VideoDotC,
  VideoMain,
} from './index.style'
import Container from '../../Container'
import Grid from '../../Grid'
import GridItem from '../../GridItem'
import Spacer from '../../Spacer'
import Button from '../../Button'
import { Heading2, TextBody } from '../../TextStyles'
import AnimateSplitText from '../../animation/AnimateSplitText'
import IconPlay from '../../svgs/IconPlay'
import Dot from '../../svgs/Dot'
import DotHalf from '../../svgs/DotHalf'
import Modal from '../../Modal'
import VideoEmbed from '../../VideoEmbed'

const Video = ({ text, videoId }) => {
  const [showDialog, setShowDialog] = useState(false)

  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  return (
    <>
      <VideoMain>
        <VideoDotA>
          <Dot />
        </VideoDotA>

        <VideoDotB>
          <Dot />
        </VideoDotB>

        <Container>
          <Spacer size={[80, 240]} />

          <Grid>
            <GridItem
              tabletP={14}
              tabletPStart={2}
              tabletL={11}
              tabletLStart={2}
            >
              <VideoText>
                <VideoDotC>
                  <DotHalf />
                </VideoDotC>

                <Heading2>
                  <AnimateSplitText>{text}</AnimateSplitText>
                </Heading2>
              </VideoText>

              <Spacer size={[30, 74]} />

              <VideoCTA>
                <Button
                  round
                  roundIconWidth="26.13%"
                  onClick={openDialog}
                  label={'Watch the video'}
                >
                  <IconPlay />
                </Button>

                <Spacer axis="horizontal" size={[20, 30]} />

                <TextBody>Watch video</TextBody>
              </VideoCTA>
            </GridItem>
          </Grid>

          <Spacer size={[80, 200]} />
        </Container>
      </VideoMain>

      <Modal
        isOpen={showDialog}
        onDismiss={closeDialog}
        ariaLabel="A knowledge video"
      >
        <VideoEmbed type="youtube" id={videoId} />
      </Modal>
    </>
  )
}

Video.propTypes = {
  text: PropTypes.string,
  videoId: PropTypes.string,
}

export default Video
