import React from 'react'
import PropTypes from 'prop-types'
import {
  VideoEmbedMain,
  VideoEmbedIframe,
  VideoEmbedOverlay,
  VideoEmbedWrapper,
} from './index.style'

const VideoEmbed = ({
  type,
  id,
  autoplay,
  loop,
  maxWidthVW = 70,
  maxHeightVH = 70,
}) => {
  const dimensions = [16, 9]
  let src

  if (type === 'youtube') {
    src = `https://www.youtube.com/embed/${id}?rel=0${
      autoplay ? '&autoplay=1&mute=1&controls=0&modestbranding=1' : ''
    }${loop ? `&loop=1&playlist=${id}` : ``}`
  }

  if (type === 'vimeo') {
    src = `https://player.vimeo.com/video/${id}?byline=0&portrait=0${
      autoplay ? '&autoplay=1&muted=1' : ''
    }${loop ? `&loop=1` : ``}`
  }

  return (
    <VideoEmbedWrapper
      dimensions={dimensions}
      maxWidthVW={maxWidthVW}
      maxHeightVH={maxHeightVH}
    >
      <VideoEmbedMain dimensions={dimensions}>
        <VideoEmbedIframe
          type="text/html"
          src={src}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />

        {autoplay && <VideoEmbedOverlay />}
      </VideoEmbedMain>
    </VideoEmbedWrapper>
  )
}

VideoEmbed.propTypes = {
  type: PropTypes.oneOf(['youtube', 'vimeo']).isRequired,
  id: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  maxWidthVW: PropTypes.number,
  maxHeightVH: PropTypes.number,
}

export default VideoEmbed
