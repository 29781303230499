import React from 'react'

const IconPlay = ({
  width = 27,
  height = 27,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27 27"
      aria-hidden="true"
      focusable="false"
      fill="none"
      {...svgAttributes}
    >
      <path fill={fill} d="M26.126 13.063 6.53 24.376V1.75l19.595 11.313Z" />
    </svg>
  )
}

export default IconPlay
