import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  FooterContent,
  FooterCopyright,
  FooterCTA,
  FooterCtaCanvas,
  FooterCtaContentMask,
  FooterCtaInner,
  FooterLinks,
  FooterMain,
  FooterScrollToTop,
  FooterSocials,
  FooterWrapper,
} from './index.style'
import Container from '../Container'
import Spacer from '../Spacer'
import LinkButton from '../LinkButton'
import {
  Heading2,
  TextBodyLarge,
  TextBodyXSmall,
  TextBodyXXSmall,
} from '../TextStyles'
import AnimateSplitText from '../animation/AnimateSplitText'
import Grid from '../Grid'
import GridItem from '../GridItem'
import PageTransitionLink from '../PageTransitionLink'
import IconArrowheadUp from '../svgs/IconArrowheadUp'
import { colors } from '../../styles/vars/colors.style'
import Dots from '../Dots'

const Footer = () => {
  const $content = useRef([])
  const {
    contentfulGlobals: { footerTitle, footerCtaText, footerCtaTarget, socials },
    contentfulProducts,
    contentfulWhoWeAre,
    // contentfulPartners,
    contentfulResources,
    contentfulBlog,
    allContentfulStandardContent,
  } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        footerTitle
        footerCtaText
        footerCtaTarget {
          ... on Node {
            ...ContentfulLink
          }
        }
        socials {
          name
          url
        }
      }
      allContentfulStandardContent {
        nodes {
          seoTitle
          slug
        }
      }
      contentfulProducts {
        seoTitle
        slug
      }
      contentfulBlog {
        seoTitle
        slug
      }
    }
  `)

  const standardLinks = allContentfulStandardContent.nodes.map(link => ({
    to: link.slug,
    text: link.seoTitle,
  }))

  const links = [
    {
      to: contentfulProducts.slug,
      text: contentfulProducts.seoTitle,
    },
    // {
    //   to: contentfulWhoWeAre.slug,
    //   text: contentfulWhoWeAre.seoTitle,
    // },
    // {
    //   to: contentfulPartners.slug,
    //   text: contentfulPartners.seoTitle,
    // },
    // {
    //   to: contentfulResources.slug,
    //   text: contentfulResources.seoTitle,
    // },
    {
      to: contentfulBlog.slug,
      text: contentfulBlog.seoTitle,
    },
    {
      to: 'https://help.emberfund.io/en/',
      text: 'Resources',
    },
    ...standardLinks,
  ]

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <FooterCTA>
        <Container fullWidth>
          <FooterCtaInner>
            <FooterCtaCanvas>
              <Dots content={$content.current} />
            </FooterCtaCanvas>

            <Spacer size={[90, 168]} />

            <FooterCtaContentMask ref={ref => ($content.current[0] = ref)}>
              <Heading2 as="h3">
                <AnimateSplitText>{footerTitle}</AnimateSplitText>
              </Heading2>
            </FooterCtaContentMask>

            <Spacer size={[20, 38]} />

            <FooterCtaContentMask ref={ref => ($content.current[1] = ref)}>
              <LinkButton
                text={footerCtaText}
                target={footerCtaTarget}
                mobilefill
              />
            </FooterCtaContentMask>

            <Spacer size={[90, 168]} />
          </FooterCtaInner>
        </Container>
      </FooterCTA>

      <FooterWrapper>
        <Container fullWidth>
          <FooterMain>
            <FooterContent>
              <Grid>
                <GridItem
                  tabletP={8}
                  tabletPStart={2}
                  tabletL={6}
                  tabletLStart={2}
                  deskL={4}
                  deskLStart={2}
                >
                  <FooterLinks>
                    {React.Children.toArray(
                      links.map(link => (
                        <PageTransitionLink to={`${link.to}/`}>
                          <TextBodyLarge>
                            <AnimateSplitText>{link.text}</AnimateSplitText>
                          </TextBodyLarge>
                        </PageTransitionLink>
                      ))
                    )}
                  </FooterLinks>
                </GridItem>

                <GridItem
                  tabletP={2}
                  tabletPStart={14}
                  deskM={2}
                  deskMStart={15}
                >
                  <FooterSocials>
                    {React.Children.toArray(
                      socials.map(social => (
                        <a href={social.url}>
                          <TextBodyXSmall>
                            <AnimateSplitText>{social.name}</AnimateSplitText>
                          </TextBodyXSmall>
                        </a>
                      ))
                    )}
                  </FooterSocials>
                </GridItem>
              </Grid>
            </FooterContent>

            <FooterCopyright>
              <Spacer size={[21, 30]} />

              <TextBodyXXSmall>
                <AnimateSplitText>
                  &copy; Ember Fund Inc. All Rights Reserved{' '}
                  {new Date().getFullYear()}.
                </AnimateSplitText>
              </TextBodyXXSmall>

              <Spacer size={[21, 30]} />

              <FooterScrollToTop onClick={scrollToTop}>
                <IconArrowheadUp responsive={false} fill={colors.light} />
              </FooterScrollToTop>
            </FooterCopyright>
          </FooterMain>
        </Container>
      </FooterWrapper>
    </>
  )
}

export default Footer
