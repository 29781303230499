import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'

export const VideoEmbedWrapper = styled.div`
  ${({ dimensions, maxWidthVW, maxHeightVH }) => {
    if (dimensions) {
      return `
        width: 94vw;
        height: ${(dimensions[1] / dimensions[0]) * 94}vw;
        max-width: ${(dimensions[0] / dimensions[1]) * 94}vh;
        max-height: 94vh;

        ${mq.tabletL} {
          width: ${maxWidthVW}vw;
          height: ${(dimensions[1] / dimensions[0]) * maxWidthVW}vw;
          max-width: ${(dimensions[0] / dimensions[1]) * maxHeightVH}vh;
          max-height: ${maxHeightVH}vh;
        }
      `
    }
  }}
`

export const VideoEmbedMain = styled.div`
  position: relative;
  overflow: hidden;

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${({ dimensions }) =>
      dimensions ? `${(dimensions[1] / dimensions[0]) * 100}%` : '56.25%'};
  }
`

export const VideoEmbedIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`

export const VideoEmbedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
