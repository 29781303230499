import React, { useRef, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '../PageTransitionLink'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Spacer from '../Spacer'
import { TextBodySmall } from '../TextStyles'
import { useStore } from '../../Store'
import Dot from '../svgs/Dot'
import DotHalf from '../svgs/DotHalf'
import {
  NavMain,
  Menu,
  MenuItem,
  MenuWrapper,
  MenuBackground,
  MenuItemBorder,
  MenuFooter,
  MenuCTAs,
  MenuCTA,
  MenuSocials,
  MenuSocialsItem,
  MenuSocialsLink,
  DotA,
  DotB,
  DotC,
  MenuItemMask,
  MenuItemText,
  MenuTextMask,
  NavBackground,
  MenuBackgroundWrapper,
} from './index.style'
import logoAppStore from './images/app-store.svg'
import logoGooglePlay from './images/google-play.svg'
import gsap from 'gsap'
import { GSDevTools } from 'gsap/GSDevTools'

gsap.registerPlugin(GSDevTools)

const Nav = ({ pathname }) => {
  const [store] = useStore()
  const { navIsOpen } = store
  const $nav = useRef()
  const $navContainer = useRef()
  const $LightBackground = useRef()
  const $DarkBackground = useRef()
  const $PageTextItems = useRef()
  const $PageItemBorders = useRef()
  const $DownloadText = useRef()
  const $FooterTextItems = useRef()
  const $DownloadImages = useRef()
  const $Dots = useRef()
  const tl = useRef()

  const {
    contentfulGlobals,
    contentfulProducts,
    contentfulWhoWeAre,
    // contentfulPartners,
    contentfulResources,
    contentfulBlog,
  } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        socials {
          name
          url
        }
        appStoreDownloadUrl
        googlePlayDownloadUrl
      }
      contentfulProducts {
        seoTitle
        slug
      }
      contentfulBlog {
        seoTitle
        slug
      }
    }
  `)

  const { socials, appStoreDownloadUrl, googlePlayDownloadUrl } =
    contentfulGlobals

  const menuItems = [
    contentfulProducts,
    // contentfulWhoWeAre,
    // contentfulPartners,
    // contentfulResources,
    contentfulBlog,
    {
      to: 'https://help.emberfund.io/en/',
      seoTitle: 'Resources',
    },
  ]

  const setupAnimation = () => {
    const $ = gsap.utils.selector($nav)
    $DarkBackground.current = $('.MenuBackground')
    $PageTextItems.current = $('.MenuItemText')
    $PageItemBorders.current = $('.MenuItemBorder')
    $DownloadText.current = $('.MenuDownload')
    $FooterTextItems.current = $('.MenuFooterText')
    $DownloadImages.current = $('.MenuCta')
    $Dots.current = $('.MenuDot')

    gsap.set($nav.current, {
      display: 'none',
    })

    gsap.set($DarkBackground.current, {
      transformOrigin: '50% 0%',
      scaleY: 0,
    })

    gsap.set(
      [$PageTextItems.current, $DownloadText.current, $FooterTextItems.current],
      {
        opacity: 0,
        yPercent: 100,
      }
    )

    gsap.set($PageItemBorders.current, {
      transformOrigin: '0% 50%',
      scaleX: 0,
    })

    gsap.set($DownloadImages.current, {
      opacity: 0,
    })

    gsap.set($Dots.current, {
      scale: 0,
    })
  }

  const openAnimation = () => {
    tl.current = gsap.timeline({
      id: 'open',
      defaults: {
        ease: 'power3.inOut',
        duration: 0.5,
      },
    })
    const staggerDuration = 0.07

    tl.current
      .set($nav.current, {
        clearProps: 'display',
      })
      .to($DarkBackground.current, {
        scaleY: 1,
        ease: 'power2.inOut',
        duration: 0.4,
      })
      .to(
        $LightBackground.current,
        {
          opacity: 1,
          duration: 0.4,
        },
        '<'
      )
      .to(
        $PageTextItems.current,
        {
          yPercent: 0,
          opacity: 1,
          stagger: staggerDuration,
        },
        '<0.1'
      )
      .to(
        $PageItemBorders.current,
        {
          scaleX: 1,
          stagger: staggerDuration,
        },
        '<0.2'
      )
      .to(
        $DownloadText.current,
        {
          yPercent: 0,
          opacity: 1,
        },
        '<'
      )
      .to(
        $DownloadImages.current,
        {
          opacity: 1,
          stagger: staggerDuration,
        },
        `<${staggerDuration}`
      )
      .to(
        $FooterTextItems.current,
        {
          yPercent: 0,
          opacity: 1,
          stagger: staggerDuration,
        },
        `<`
      )
      .to(
        $Dots.current,
        {
          scale: 1,
          stagger: staggerDuration,
        },
        `<${staggerDuration}`
      )

    // GSDevTools.create({
    //   animation: 'open',
    // })
  }

  const closeAnimation = () => {
    tl.current = gsap.timeline({
      onComplete: () => {
        $navContainer.current.scrollTop = 0
        gsap.set($nav.current, {
          display: 'none',
        })
      },
      defaults: {
        ease: 'power3.inOut',
        duration: 0.5,
      },
    })

    tl.current
      .to(
        [
          $PageTextItems.current,
          $DownloadText.current,
          $FooterTextItems.current,
        ],
        {
          opacity: 0,
          yPercent: 100,
        }
      )
      .to(
        $PageItemBorders.current,
        {
          transformOrigin: '0% 50%',
          scaleX: 0,
        },
        0
      )
      .to(
        $DownloadImages.current,
        {
          opacity: 0,
        },
        0
      )
      .to(
        $Dots.current,
        {
          scale: 0,
        },
        0
      )
      .to($LightBackground.current, {
        opacity: 0,
      })
      .to(
        $DarkBackground.current,
        {
          scaleY: 0,
        },
        '<'
      )
  }

  useLayoutEffect(() => {
    setupAnimation()
  }, [])

  useEffect(() => {
    if (navIsOpen) {
      openAnimation()
    } else {
      closeAnimation()
    }

    return () => {
      if (tl.current) {
        tl.current.kill()
      }
    }
  }, [navIsOpen])

  return (
    <NavMain ref={$nav} open={navIsOpen} aria-hidden={!navIsOpen}>
      <NavBackground ref={$LightBackground} />

      <MenuBackgroundWrapper>
        <Container>
          <Grid>
            <GridItem tabletL={9} tabletLStart={8}>
              <MenuBackground className="MenuBackground" />
            </GridItem>
          </Grid>
        </Container>
      </MenuBackgroundWrapper>

      <Container ref={$navContainer}>
        <Grid>
          <GridItem tabletL={9} tabletLStart={8} tabletLOrder={1}>
            <MenuBackground className="MenuBackground" />

            <Grid>
              <GridItem tabletL={5} tabletLStart={3}>
                <MenuWrapper>
                  <Menu>
                    {React.Children.toArray(
                      menuItems.map((item, itemIndex) => (
                        <MenuItem>
                          <PageTransitionLink to={item.to || `${item.slug}/`}>
                            <MenuItemMask>
                              <MenuItemText
                                className="MenuItemText"
                                active={pathname.indexOf(item.slug) > -1}
                              >
                                {item.seoTitle}
                              </MenuItemText>
                            </MenuItemMask>
                          </PageTransitionLink>

                          {itemIndex < menuItems.length - 1 && (
                            <MenuItemBorder className="MenuItemBorder" />
                          )}
                        </MenuItem>
                      ))
                    )}
                  </Menu>
                </MenuWrapper>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem tabletL={7} tabletLStart={1} tabletLOrder={0}>
            <DotA className="MenuDot">
              <Dot />
            </DotA>
            <DotB className="MenuDot">
              <DotHalf />
            </DotB>
            <DotC className="MenuDot">
              <DotHalf />
            </DotC>

            <Grid>
              <GridItem tabletL={4} tabletLStart={2} desk={3} deskStart={3}>
                <MenuFooter>
                  <MenuTextMask>
                    <TextBodySmall className="MenuDownload">
                      Download Ember:
                    </TextBodySmall>
                  </MenuTextMask>

                  <Spacer size={[8, 15]} />

                  <MenuCTAs>
                    <MenuCTA
                      href={appStoreDownloadUrl}
                      aria-label={'Download Ember on the App Store'}
                    >
                      <img
                        className="MenuCta"
                        src={logoAppStore}
                        alt="Available on the App Store"
                      />
                    </MenuCTA>

                    <MenuCTA
                      href={googlePlayDownloadUrl}
                      aria-label={'Download Ember on Google Play'}
                    >
                      <img
                        className="MenuCta"
                        src={logoGooglePlay}
                        alt="Get it on Google Play"
                      />
                    </MenuCTA>
                  </MenuCTAs>

                  <Spacer size={[50, 100]} />

                  <MenuSocials columns={socials.length > 3}>
                    {React.Children.toArray(
                      socials.map(social => (
                        <MenuSocialsItem>
                          <MenuSocialsLink href={social.url}>
                            <TextBodySmall className="MenuFooterText">
                              {social.name}
                            </TextBodySmall>
                          </MenuSocialsLink>
                        </MenuSocialsItem>
                      ))
                    )}
                  </MenuSocials>
                </MenuFooter>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </Container>
    </NavMain>
  )
}

Nav.propTypes = {
  pathname: PropTypes.string,
}

export default Nav
