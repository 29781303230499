import styled from 'styled-components'
import { mq } from '../../../styles/vars/media-queries.style'

export const VideoMain = styled.div`
  position: relative;
  overflow: hidden;
`

export const VideoCTA = styled.div`
  display: flex;
  align-items: center;
`

export const VideoText = styled.div`
  position: relative;
`

export const VideoDotA = styled.div`
  position: absolute;
  top: 5.8rem;
  left: 0;
  transform: translateX(-50%);
  mix-blend-mode: multiply;
`

export const VideoDotB = styled.div`
  position: absolute;
  top: 11.4rem;
  right: 0;
  transform: translateX(50%);
  mix-blend-mode: multiply;
`

export const VideoDotC = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(200%, -50%) rotate(90deg);
  display: none;
  mix-blend-mode: multiply;

  ${mq.tabletL} {
    display: block;
  }
`
