exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogs-all-js": () => import("./../../../src/pages/blogs/all.js" /* webpackChunkName: "component---src-pages-blogs-all-js" */),
  "component---src-pages-coins-[id]-js": () => import("./../../../src/pages/coins/[id].js" /* webpackChunkName: "component---src-pages-coins-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolios-[id]-js": () => import("./../../../src/pages/portfolios/[id].js" /* webpackChunkName: "component---src-pages-portfolios-[id]-js" */),
  "component---src-pages-resources-search-js": () => import("./../../../src/pages/resources/search.js" /* webpackChunkName: "component---src-pages-resources-search-js" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-resource-post-js": () => import("./../../../src/templates/resource-post.js" /* webpackChunkName: "component---src-templates-resource-post-js" */),
  "component---src-templates-standard-content-js": () => import("./../../../src/templates/standard-content.js" /* webpackChunkName: "component---src-templates-standard-content-js" */),
  "component---src-templates-styleguide-js": () => import("./../../../src/templates/styleguide.js" /* webpackChunkName: "component---src-templates-styleguide-js" */)
}

